<template>
  <div class="container-fluid">
    <div class="d-flex mb-5">
      <button class="btn btn-primary btn-sm ms-auto" type="button" @click="openModal({})">
        <i class="bi bi-plus-square-fill" style="top: -3px"></i>
        新增產品
      </button>
    </div>
    <div class="py-3">
      <div class="row mb-2">
        <template v-if="values.length > 0">
          <div class="col-6 col-md-4 col-lg-3 mb-3" v-for="item in values" :key="item.id">
            <div class="h-100 card">
              <div class="position-relative">
                <div class="position-absolute top-0 end-0" style="z-index: 2">
                  <button
                    class="btn btn-sm btn-outline-primary rounded-circle fs-7 m-1"
                    type="button"
                    @click="openModal(item)"
                  >
                    <i class="bi bi-pencil-fill" style="top: -3px"></i>
                  </button>
                </div>
              </div>

              <img
                :src="$imageFilters(item.imagePath)"
                class="card-img-top obj-fit-contain"
                :alt="item.alternativeText"
                style="height: 120px"
              />
              <div class="card-body">
                <h6 class="card-title mt-2 mb-1 text-primary fw-bold">{{ item.nameCh }}</h6>
                <p class="card-text mb-3 text-gray-600 fw-bold fs-8">{{ item.nameEn }}</p>
                <div v-if="item.brandInfos">
                  <p class="fs-8 mb-1 text-gray-500">經銷廠商</p>
                  <ul class="card-text text-secondary fs-8 flex-wrap d-flex">
                    <li v-for="brand in item.brandInfos" :key="brand" class="me-2 mb-1">
                      <div class="badge border-primary border px-2 py-1">
                        <router-link
                          class="fs-9 fw-light text-primary"
                          :to="{ name: 'brandDetail', params: { id: brand.nameEn } }"
                        >
                          {{ brand.nameCh || "-" }}</router-link
                        >
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </template>
        <p v-else class="py-4">目前無資料</p>
      </div>
    </div>

    <AppModal v-model="showModal" refName="productModal" title="新增/編輯產品" size="lg">
      <ProductCreate
        :prop-items="modalData"
        @finishContent="finishContent"
        @cancel="showModal = !showModal"
      />
    </AppModal>
  </div>
</template>

<script>
import { apiQueryAdminProductBrand } from "@/api/product";
import ProductCreate from "../../components/ProductCreate.vue";

export default {
  components: {
    ProductCreate,
  },
  data() {
    return {
      showModal: false,
      values: [],
      modalData: {},
    };
  },
  methods: {
    async queryProduct() {
      this.$vLoading(true);
      try {
        const res = await apiQueryAdminProductBrand();
        const { code, data, message } = res.data;
        if (code === 200) {
          this.values = data;
        } else {
          this.$vHttpsNotice({ statusCode: code, title: message });
        }
      } catch (error) {
        this.$vErrorNotice();
      } finally {
        this.$vLoading(false);
      }
    },
    finishContent() {
      this.modalData = {};
      this.showModal = false;
      this.queryProduct();
    },
    openModal(item) {
      this.modalData = item || {};
      this.showModal = true;
    },
  },
  created() {
    this.queryProduct();
  },
};
</script>
