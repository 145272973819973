import APIService from "../common/https";

export const apiQueryProduct = (params) => APIService.query("product", params);
export const apiQueryAdminProduct = () => APIService.query("product", {}, { tokenRequired: true });
export const apiQueryAdminProductBrand = () =>
  APIService.query("product/brand", {}, { tokenRequired: true });

export const apiGetProduct = (nameEn) => APIService.get("product", nameEn);
export const apiQueryProductBrand = () => APIService.query("product/brand");
export const apiCreateProduct = (data) => APIService.create("product", data);
export const apiUpdateProduct = (nameEn, data) => APIService.update("product", nameEn, data);

export const apiGetProductItem = (uuid) => APIService.get("item", uuid);
export const apiCreateProductItem = (data) => APIService.create("item", data);
export const apiUpdateProductItem = (uuid, data) => APIService.update("item", uuid, data);
export const apiDeleteProductItem = (uuid) => APIService.delete("item", uuid);
