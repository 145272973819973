<template>
  <VForm @submit="createUpdateContent" ref="form" v-slot="{ errors }">
    <div class="modal-body p-4">
      <div class="row mb-3 align-items-center">
        <div class="col-md-7">
          <div
            class="w-100 bg-light mb-2"
            :class="tempItem.imagePath ? 'show' : ''"
            style="height: 200px"
          >
            <img
              v-if="tempItem.imagePath"
              class="rounded-start w-100 obj-fit-contain"
              :src="$imageFilters(tempItem.imagePath)"
              alt="圖片"
            />
          </div>
        </div>
        <div class="col-md-5">
          <AppUpload @uploadSuccess="uploadSuccess" title="產品圖片LOGO" />
        </div>
      </div>
      <div class="mb-3">
        <div class="mb-3">
          <label for="nameCh" class="form-label">中文名稱</label>
          <div class="input-group">
            <VField
              id="nameCh"
              name="中文名稱"
              type="text"
              class="form-control border"
              :class="{ 'is-invalid': errors['中文名稱'] }"
              placeholder="輸入中文名稱"
              v-model="tempItem.nameCh"
              maxlength="30"
              rules="max:30|required"
            />
            <span class="input-group-text bg-white fs-9 text-info p-2"
              >{{ tempItem.nameCh?.length }}/30</span
            >
            <ErrorMessage name="中文名稱" class="invalid-feedback"></ErrorMessage>
          </div>
        </div>
        <div class="mb-3">
          <label for="nameEn" class="form-label">英文名稱</label>
          <div class="input-group">
            <VField
              id="nameEn"
              name="英文名稱"
              type="text"
              class="form-control border"
              :class="{
                'is-invalid': errors['英文名稱'],
              }"
              placeholder="輸入英文名稱"
              v-model="tempItem.nameEn"
              maxlength="30"
              :rules="rule"
            />
            <span class="input-group-text bg-white fs-9 text-info p-2"
              >{{ tempItem.nameEn?.length }}/30</span
            >
            <ErrorMessage name="英文名稱" class="invalid-feedback"></ErrorMessage>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" @click="$emit('cancel')">取消</button>
      <button type="submit" class="btn btn-primary">儲存</button>
    </div>
  </VForm>
</template>

<script>
import { apiCreateProduct, apiUpdateProduct } from "@/api/product";

export default {
  props: {
    propItems: {
      type: Object,
      default: () => ({}),
    },
  },
  inject: ["emitter"],
  data() {
    return {
      tempItem: {
        imageId: "",
        nameEn: "",
        nameCh: "",
      },
      rule: { required: true, max: 100, regex: /^[A-Za-z0-9- "/]+$/ },
    };
  },
  computed: {
    productData() {
      const productItems = ["imageId", "nameEn", "nameCh"];
      return productItems.reduce((acc, item) => {
        return Object.is(this.tempItem[item], this.propItems[item])
          ? acc
          : { ...acc, [item]: this.tempItem[item] };
      }, {});
    },
  },
  methods: {
    async createUpdateContent() {
      if (!this.tempItem.imagePath) {
        this.emitter.emit("notice-message", { style: "warning", title: "請上傳圖片" });
        return;
      }
      try {
        this.$vLoading(true);
        const { nameEn: propsNameEn } = this.propItems;
        const slug = encodeURIComponent(propsNameEn);
        const res = propsNameEn
          ? await apiUpdateProduct(slug, this.productData)
          : await apiCreateProduct(this.productData);
        const { code, message } = res.data;
        if (code === 200) {
          this.$vHttpsNotice({
            statusCode: code,
            title: `${propsNameEn ? "編輯" : "新增"}產品`,
          });
          this.$emit("finishContent");
        } else {
          this.$vHttpsNotice({ statusCode: code, title: message });
        }
      } catch (error) {
        this.$vErrorNotice();
      } finally {
        this.$vLoading(false);
      }
    },
    uploadSuccess(item) {
      const { imageId, imagePath } = item;
      this.tempItem.imagePath = imagePath;
      this.tempItem.imageId = imageId;
    },
    init() {
      this.tempItem = {
        imageId: "",
        nameEn: "",
        nameCh: "",
      };
    },
  },
  watch: {
    propItems(val) {
      if (val.nameEn) {
        this.tempItem = { ...val };
      } else {
        this.$refs.form.resetForm();
        this.init();
      }
    },
  },
};
</script>
